@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bebas+Neue&family=Edu+VIC+WA+NT+Beginner:wght@400..700&family=New+Amsterdam&family=Saira:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@400..700&family=New+Amsterdam&family=Saira:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Edu+VIC+WA+NT+Beginner:wght@400..700&family=New+Amsterdam&family=Saira:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.index-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(241, 241, 241);
}

.index-page > img {
  width: 100vw;
}

.products {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  width: 1000px;
  gap: 1.5rem;
}


.products-item {
  overflow: hidden;
  gap: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.253);
  transition: all 300ms;
  cursor: pointer;
}

.products-item:hover {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.363);
}

.products-item:hover > img {
  transform: scale(1.1);
}

.products-item > img {
  width: 232px;
  transition: all 300ms;
}

.title-product {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  width: 180px;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.price-product {
  font-family: "Edu VIC WA NT Beginner", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 1.2rem;
  
}

.old-price {
  font-family: "Edu VIC WA NT Beginner", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  text-decoration: line-through;
  color: rgb(158, 157, 157);
  font-size: 0.8rem;
}

.price-content {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 50px;
}

.description-product {
  margin: 0;
  padding: 0.8rem;
  height: 200px;
}

.button {
  display: flex;
  width: fit-content;
  margin: auto;
  margin-top: 1rem;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid rgb(255, 119, 70);
  background-color: rgb(255, 119, 70);
  border-radius: 20px;
  color: black;
  padding: 10px;
  font-family: "Amatic SC",cursive ;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1rem;
  transition: all 300ms;
}

.button:hover {
  background-color: rgb(233, 233, 233);
}

.button:visited {
  color: black;
}